import Typography from '@mui/material/Typography';
import styled from '@emotion/styled';
import theme from 'theme';

/* Navigation Link style */
export const NavLink = styled(Typography)`
  ${theme.breakpoints.down('lg')} {
    font-size: 1.125rem;
  }
  color: ${theme.palette.secondary.contrastText};
  text-transform: uppercase;
  font-size: 1.25rem;
  font-weight: 300;
  white-space: nowrap;
  cursor: pointer;
  :hover {
    color: #E2DDBB;
  }
  :active {
    color: #E2DDBB;
  }
`;

/* Page Title Background */
export const PageTitleBackground = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: ${theme.palette.secondary.contrastText};
  padding: ${theme.spacing(5)};
  margin: ${theme.spacing(5, 0, 4, 0)};
`;

/* Page Title */
export const PageTitle = styled(Typography)`
  ${theme.breakpoints.down('lg')} {
    font-size: 2.25rem;
  }
  ${theme.breakpoints.down('md')} {
    font-size: 1.5rem;
  }
  font-family: Yu Gothic;
  font-size: 3.3125rem;
  letter-spacing: 0px;
  line-height: 3.5rem;
  text-transform: uppercase;
  text-align: center;
  white-space: nowrap;
`;

/* Page Introduction Text Container*/
export const PageIntroductionTextContainer = styled.div`
  ${theme.breakpoints.down('lg')} {
    width: 100%;
    max-width: 700px;
  }
  ${theme.breakpoints.down('md')} {
    padding: ${theme.spacing(0, 3, 4, 3)};
  }
  width: 700px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${theme.spacing(2)};
  text-align: center;
  padding-bottom: ${theme.spacing(4)};
  margin: auto;
`;

/* Full Screen Width Image Container */
export const FullImageContainer = styled.div`
${theme.breakpoints.down('lg')} {
  max-width: 810px;
}
${theme.breakpoints.down('md')} {
  overflow: scroll;
}
position: relative;
width: 100%;
max-width: 1200px;
margin: auto;
background-color: ${theme.palette.secondary.contrastText};
padding: ${theme.spacing(4)};
`;

/* Audio for the pages */
export const Audio = styled.audio`
  display: hidden;
`;
