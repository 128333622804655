import { NavLink } from 'components/styling/GeneralStyledComponents';
import { useCallback } from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import Logo from './Logo';
import Typography from '@mui/material/Typography';
import styled from '@emotion/styled';
import theme from 'theme/index';
import useMediaQuery from '@mui/material/useMediaQuery';

const Background = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  padding: ${theme.spacing(5, 3)};
  background-color: ${theme.palette.secondary.main};
`;

const Content = styled.div`
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${theme.spacing(5)};
`;

const Row = styled.div`
  ${theme.breakpoints.down('md')} {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: ${theme.spacing(5)};
  }
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const TabletColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing(5)};
`;

const TabletRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const LogoContainer = styled.div`
  width: fit-content;
  height: fit-content;
  cursor: pointer;
`;

const NavLinks = styled.div`
  ${theme.breakpoints.down('md')} {
    display: none;
  }
  height: 100%;  
  display: flex;  
  flex-direction: row;
  gap: ${theme.spacing(2)};
  align-items: center;
`;

const SmallLink = styled(Typography)`
  color: ${theme.palette.secondary.contrastText};
  font-size: 0.75rem;
  text-decoration: underline;
  text-underline-offset: 3px;
  cursor: pointer;
  :hover {
    color: #E2DDBB;
  }
`;

const BottomColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${theme.spacing(2)};
`;

const Footer = () => {
  const {
    t,
  } = useTranslation();
  const router = useRouter();

  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'lg'));

  const handleLogoClick = useCallback(() => {
    router.push('/');
  }, [router,]);

  const handlePrivacyPolicyClick = useCallback(() => {
    router.push('/privacy-policy');
  }, [router,]);

  /* Navigation link click */
  const handleNavigationLinkClick = useCallback((link: string) => () => {
    router.push(`/${link}`);
  }, [router,]);

  const renderLinks = () => {
    return <>
      <NavLink onClick={handleNavigationLinkClick('life')}>{t('Life')}</NavLink>
      <NavLink onClick={handleNavigationLinkClick('gallery')}>{t('People')}</NavLink>
      <NavLink onClick={handleNavigationLinkClick('roles')}>{t('Georg Magnus')}</NavLink>
      <NavLink onClick={handleNavigationLinkClick('maps')}>{t('Maps')}</NavLink>
      <NavLink onClick={handleNavigationLinkClick('fun')}>{t('Fun')}</NavLink>
      <NavLink onClick={handleNavigationLinkClick('brahelinna')}>{t('Brahelinna')}</NavLink>
      <NavLink onClick={handleNavigationLinkClick('info')}>{t('Info')}</NavLink>
    </>;
  };

  return <Background>
    <Content>

      {
        isTablet ?
          /* Tablet */
          <>
            <TabletColumn>
              <TabletRow>
                <LogoContainer onClick={handleLogoClick}>
                  <Logo isFooter />
                </LogoContainer>
              </TabletRow>
              <NavLinks>
                {renderLinks()}
              </NavLinks>
            </TabletColumn>
          </> :
          /* Desktop + Mobile */
          <>
            <Row>
              <LogoContainer onClick={handleLogoClick}>
                <Logo isFooter />
              </LogoContainer>
              <NavLinks>
                {renderLinks()}
              </NavLinks>
            </Row>
          </>
      }

      <BottomColumn>
        <SmallLink onClick={handlePrivacyPolicyClick}>
          Tietosuojaseloste
        </SmallLink>
      </BottomColumn>

    </Content>
  </Background>;
};
export default Footer;
