/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable react/jsx-indent */

import { NavLink } from 'components/styling/GeneralStyledComponents';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import AppBar from '@mui/material/AppBar';
import AudioContext from 'utils/hooks/useAudio';
import CloseIcon from '@mui/icons-material/Close';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Logo from './Logo';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import styled from '@emotion/styled';
import theme from 'theme';
import useMediaQuery from '@mui/material/useMediaQuery';

const StyledAppBar = styled(AppBar)(({
  theme,
}) => `
  background-color: ${theme.palette.secondary.contrastText};
  box-shadow: none;
  position: relative; !important;
  left: 0;
  top: 0;
  width: 100%;
  height: fit-content;
  display; flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 20;
  background-image: none;
`);

const LogoContainer = styled.div`
  ${theme.breakpoints.down('md')} {
    height: 151px;
  }
  width: fit-content;
  height: 186px; 
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: ${theme.spacing(1)};
  cursor: pointer;
`;

const StyleToolbar = styled(Toolbar)(({
  theme,
}) => `
  ${theme.breakpoints.down('sm')} {
   padding: ${theme.spacing(0, 2)};
  }
  padding: ${theme.spacing(1, 2)};
  flex-grow: 1;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: ${theme.palette.secondary.main};
`);

const LeftSide = styled.div`
  justify-self: flex-start;
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const CenterPart = styled.div`
  ${theme.breakpoints.down('lg')} {
    flex: 8;
  }
  display: flex;
  flex: 3;
  height: 100%;
  gap: ${theme.spacing(3)};
  justify-content: center;
  align-items: center;
  & .MuiTypography-root {
    cursor: pointer;
  }
`;

const RightSide = styled.div`
  justify-self: flex-end;
  display: flex;
  flex: 1;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: flex-end;
`;

const ControlArea = styled.div(({
  theme,
}) => `
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${theme.spacing()};
`);

const ActionsArea = styled.div(({
  theme,
}) => `
  display: flex;
  flex-direction: row;
  align-items: center;
  gap:${theme.spacing()};
`);

const StyledIconButton = styled(IconButton)`
    &> .MuiSvgIcon-root {
      width: 40px;
      height: 40px;
      color: ${theme.palette.secondary.contrastText};
    }
  ${theme.breakpoints.down('lg')} {
    height: 30px;
    width: 30px;
    &> .MuiSvgIcon-root {
      width: 28px;
      height: 28px;
    }
  }
`;

const MusicIconButton = styled(IconButton)`
  ${theme.breakpoints.down('lg')} {
    height: 30px;
    width: 30px;
    &> .MuiSvgIcon-root {
      width: 28px;
      height: 28px;
    }
  }
  height: fit-content;
  &> .MuiSvgIcon-root {
    width: 24px;
    height: 24px;
    color: ${theme.palette.secondary.contrastText};
  }
`;

const MusicText = styled(Typography)`
  font-size: 0.75rem;
  color: ${theme.palette.secondary.contrastText};
  text-transform: uppercase;
`;

const MenuContent = styled.div`
  background-color: ${theme.palette.secondary.main};
  height: 100%;
  widht: 100%;
  padding: ${theme.spacing(3)};
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing(3)};
  opacity: 1 !important;
  justify-content: center;
  align-items: center;
`;

const NavlinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  width: fit-content;
`;

const NavLinkBorder = styled.div`
  width: 0%;
  height: 1px;
  &.active {
    width: 100%;
    height: 1px;
    background-color: #fff;
  }
`;

export interface WithNavigationControls {
  controls?: JSX.Element[];
}
export interface NavbarProps extends WithNavigationControls {
  title?: string;
}
const Navbar = ({ }: NavbarProps): JSX.Element => {
  const [anchorEl, setAnchorEl,] = React.useState<null | HTMLElement>(null);
  const [activeLink, setActiveLink,] = useState('');

  const open = Boolean(anchorEl);
  const {
    audioOn, setAudioOn,
    audioMainControlOn, setAudioMainControlOn,
  } = useContext(AudioContext);

  const {
    t,
  } = useTranslation();
  const router = useRouter();
  const pathName = router.pathname;

  const isWeb = useMediaQuery(theme.breakpoints.up('md'));

  /* Logo click */
  const handleLogoClick = useCallback(() => {
    router.push('/');
  }, [router,]);

  /* Navigation link click */
  const handleNavigationLinkClick = useCallback((link: string) => () => {
    router.push(`/${link}`);
  }, [router,]);

  /* Mobile menu open + close */
  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleAudio = useCallback(() => {
    setAudioMainControlOn(!audioMainControlOn);
    setAudioOn(!audioOn);
  }, [audioMainControlOn, audioOn, setAudioMainControlOn, setAudioOn,]);

  useEffect(() => {
    if (pathName == '/life') {
      setActiveLink('life');
    } else if (pathName == '/gallery') {
      setActiveLink('gallery');
    } else if (pathName == '/roles') {
      setActiveLink('roles');
    } else if (pathName == '/maps') {
      setActiveLink('maps');
    } else if (pathName == '/fun') {
      setActiveLink('fun');
    } else if (pathName == '/brahelinna') {
      setActiveLink('brahelinna');
    } else if (pathName == '/info') {
      setActiveLink('info');
    } else {
      setActiveLink('');
    }
  }, [pathName,]);

  const renderLinks = () => {
    return <>
      <NavlinkContainer>
        <NavLink onClick={handleNavigationLinkClick('life')}>{t('Life')}</NavLink>
        <NavLinkBorder className={activeLink == 'life' ? 'active' : ''} />
      </NavlinkContainer>
      <NavlinkContainer>
        <NavLink onClick={handleNavigationLinkClick('gallery')}>{t('People')}</NavLink>
        <NavLinkBorder className={activeLink == 'gallery' ? 'active' : ''} />
      </NavlinkContainer>
      <NavlinkContainer>
        <NavLink onClick={handleNavigationLinkClick('roles')}>{t('Georg Magnus')}</NavLink>
        <NavLinkBorder className={activeLink == 'roles' ? 'active' : ''} />
      </NavlinkContainer>
      <NavlinkContainer>
        <NavLink onClick={handleNavigationLinkClick('maps')}>{t('Maps')}</NavLink>
        <NavLinkBorder className={activeLink == 'maps' ? 'active' : ''} />
      </NavlinkContainer>
      <NavlinkContainer>
        <NavLink onClick={handleNavigationLinkClick('fun')}>{t('Fun')}</NavLink>
        <NavLinkBorder className={activeLink == 'fun' ? 'active' : ''} />
      </NavlinkContainer>
      <NavlinkContainer>
        <NavLink onClick={handleNavigationLinkClick('brahelinna')}>{t('Brahelinna')}</NavLink>
        <NavLinkBorder className={activeLink == 'brahelinna' ? 'active' : ''} />
      </NavlinkContainer>
      <NavlinkContainer>
        <NavLink onClick={handleNavigationLinkClick('info')}>{t('Info')}</NavLink>
        <NavLinkBorder className={activeLink == 'info' ? 'active' : ''} />
      </NavlinkContainer>
    </>;
  };

  return <>

    <StyledAppBar position="fixed">
      <LogoContainer onClick={handleLogoClick}>
        <Logo />
      </LogoContainer>
      <StyleToolbar >
        {
          isWeb ?
            /* Web / tablet */
            <>
              <LeftSide></LeftSide>
              <CenterPart>
                {renderLinks()}
              </CenterPart>

              <RightSide>
                <ActionsArea>
                  <ControlArea>

                    {/*  Audio control*/}
                    {
                      pathName != '/brahelinna' && pathName != '/info' ?
                        <Grid container flexDirection={'column'} alignItems={'center'}>
                          {
                            audioMainControlOn ?
                              <>
                                <MusicIconButton
                                  onClick={handleAudio}
                                  onKeyDown={handleAudio}
                                >
                                  <PauseIcon />
                                </MusicIconButton>
                                <MusicText>
                                  {t('Silence')}
                                </MusicText>
                              </> :
                              <>
                                <MusicIconButton
                                  onClick={handleAudio}
                                  onKeyDown={handleAudio}
                                >
                                  <PlayArrowIcon />
                                </MusicIconButton>
                                <MusicText>
                                  {t('Play')}
                                </MusicText>
                              </>
                          }

                        </Grid> :
                        null
                    }

                  </ControlArea>
                </ActionsArea>
              </RightSide>
            </> :

            /* Mobile */
            <>
              {
                open ?
                  /* Drawer open */
                  <StyledIconButton
                    onClick={handleMenuClose}
                    onKeyDown={handleMenuClose}
                  >
                    <CloseIcon />
                  </StyledIconButton> :
                  /* Drawer closed */
                  <StyledIconButton
                    onClick={handleMenuOpen}
                  >
                    <MenuIcon />
                  </StyledIconButton>
              }

              {/*  Audio control*/}
              {
                pathName != '/brahelinna' && pathName != '/info' ?
                  <Grid container flexDirection={'row'} justifyContent={'flex-end'} alignItems={'center'} flex={1}>
                    {
                      audioMainControlOn ?
                        <>
                          <MusicText>
                            {t('Silence')}
                          </MusicText>
                          <MusicIconButton
                            onClick={handleAudio}
                            onKeyDown={handleAudio}
                          >
                            <PauseIcon />
                          </MusicIconButton>

                        </> :
                        <>
                          <MusicText>
                            {t('Play')}
                          </MusicText>
                          <MusicIconButton
                            onClick={handleAudio}
                            onKeyDown={handleAudio}
                          >
                            <PlayArrowIcon />
                          </MusicIconButton>

                        </>
                    }

                  </Grid> :
                  null
              }
            </>
        }
      </StyleToolbar>
    </StyledAppBar>

    {/* Mobile Drawer */}
    {
      isWeb ?
        null :
        <>
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleMenuClose}
            marginThreshold={0}
            PaperProps={{
              style: {
                width: '100%',
                maxWidth: '100%',
                left: 0,
                right: 0,
              },
            }}
          >
            <MenuContent>
              {renderLinks()}
            </MenuContent>
          </Menu>
        </>
    }
  </>;
};

export default Navbar;
