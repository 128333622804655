import { useTranslation } from 'next-i18next';
import styled from '@emotion/styled';
import theme from 'theme/index';

interface LogoProps {
  isFooter?: boolean;
}

const LogoContainer = styled.div`
  width: fit-content;
  height: fit-content; 
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const Name = styled.p<{
  $isFooter
}>(({
  $isFooter,
}) => `
  ${theme.breakpoints.down('md')}{
    font-size: ${$isFooter ? '0.875rem' : '1.125rem'};
    line-height: ${$isFooter ? '0.875rem' : '1.125rem'};;
  }
  font-family: Yu Gothic;
  font-size: ${$isFooter ? '0.875rem' : '1.5rem'};
  line-height: ${$isFooter ? '0.875rem' : '1.5rem'};
  text-transform: uppercase;
  color: ${$isFooter ? theme.palette.secondary.contrastText : theme.palette.primary.contrastText};
  margin: 0;
`);

const LastName = styled.p<{
  $isFooter
}>(({
  $isFooter,
}) => `
  ${theme.breakpoints.down('lg')}{
    font-size: ${$isFooter ? '2.25rem' : '4.5rem'};
    line-height: ${$isFooter ? '2.25rem' : '4.5rem'};
  }
  ${theme.breakpoints.down('md')}{
    font-size: ${$isFooter ? '2.25rem' : '3rem'};
    line-height: ${$isFooter ? '2.25rem' : '3rem'};
  }
  font-family: SimSun;
  font-size: ${$isFooter ? '2.25rem' : '6.25rem'};
  letter-spacing: 0px;
  line-height: ${$isFooter ? '2.25rem' : '6.25rem'};
  text-transform: uppercase;
  color: ${$isFooter ? theme.palette.secondary.contrastText : theme.palette.primary.contrastText};
  margin: 0;
`);

const Museum = styled.p<{
  $isFooter
}>(({
  $isFooter,
}) => `
  ${theme.breakpoints.down('md')}{
    font-size: ${$isFooter ? '0.875rem' : '1rem'};
    line-height: ${$isFooter ? '0.875rem' : '1rem'};
  }
  font-family: Yu Gothic;
  font-size: ${$isFooter ? '0.875rem' : '1rem'};
  line-height: ${$isFooter ? '0.875rem' : '1rem'};
  text-transform: uppercase;
  color: ${$isFooter ? theme.palette.secondary.contrastText : theme.palette.primary.contrastText};
  margin: 0;
`);

const Logo = ({
  isFooter,
}: LogoProps) => {
  const {
    t,
  } = useTranslation();

  return <LogoContainer>
    <Name $isFooter={isFooter}>
      Georg Magnus
    </Name>
    <LastName $isFooter={isFooter}>
      Sprengtporten
    </LastName>
    <Museum $isFooter={isFooter}>
      {t('Virtual museum')}
    </Museum>
  </LogoContainer>;
};
export default Logo;
